import React, { FunctionComponent } from 'react';
import { About, Layout } from '../components';

const AboutPage: FunctionComponent = () => (
  <Layout>
    <About />
  </Layout>
);

export default AboutPage;
